<template>
    <CatalogPreviewFull/>
</template>

<script>
import CatalogPreviewFull from './Catalog.vue';

export default {
components:{CatalogPreviewFull}
}
</script>

<style scoped>

</style>