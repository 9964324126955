<template>
    <div class="catalog" id="catalog">
        <div class="side-drawer slide-in-right" v-if="drawer" id="drawer">
            <div class="drawer-header" @click="toggle_drawer">
                <div class="logo">
                    <img :src="catalog.main_icon" alt="" class="img-max">
                </div>
                <i class="el-icon-close close-menu" id="menu_icon"/>
            </div>
        </div>

        <div class="loading" v-show="state == 'loading'">
            <p v-if="!in_construction">טוען, אנא המתן...</p>
            <p v-if="in_construction">הקטלוג בבנייה, עמכם סליחה.</p>
        </div>

        <div class="start" v-show="state == 'welcome'">
            <div class="logo-wrapper">
                <img :src="catalog.main_icon" alt="" class="welcome-logo">
            </div>
            <p v-if="catalog.name" class="welcome cat-title">{{catalog.name}}</p>
            <p v-if="catalog.slogan" class="welcome slogan">{{catalog.slogan}}</p>
            <div class="start-btn">
                <el-button :type="catalog.btn_type" style="width: 130px;" @click="start_catalog">{{catalog.call_to_action_text}}</el-button>
            </div>
        </div>

        <div class="main-catalog" v-if="state == 'catalog'">
            <div class="menu" @click="toggle_drawer">
                <i class="el-icon-menu" id="menu_icon"/>
            </div>
            <div class="top-center">
                <p class="cat-title">{{catalog.name}}</p>
                <p class="slogan">{{catalog.slogan}}</p>
            </div>
            <div class="logo">
                <img :src="catalog.main_icon" alt="" class="img-max">
            </div>
            <div class="path-bar" id="ui-bar">
                <div class="path-home">
                    <i class="el-icon-s-home" id="ui-icon"/>
                </div>
                <div class="current-category">
                    {{current_category.name}}
                </div>
            </div>
            <div class="category-content">

            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectAuth, projectFirestore } from '../../firebase/config';
import Swal from 'sweetalert2';
export default {
setup(){
    const state = ref('loading');
    const catalog = ref({});
    const loading = ref(false);
    const in_construction = ref(false);

    setTimeout(() => {
        projectFirestore.collection('Apps').doc('Catalog')
        .collection('Users').doc(projectAuth.currentUser.uid).onSnapshot((snapshot) => {
            if(snapshot.exists){
                catalog.value = snapshot.data();
                update_screen('welcome');
                get_products();
            }else{
                in_construction.value = true;
            }
        })
    }, 2000);

    const update_screen = (type) => {
        setTimeout(() => {
            const el = document.getElementById('catalog');
            if(el){
                el.style.backgroundColor = catalog.value.color_background;
                el.style.color = catalog.value.color_text;
            }
            const titles = document.getElementsByClassName('cat-title');
            if(titles){
                titles.forEach(title => {
                    title.style.color = catalog.value.color_title;
                })
            }

            const menu = document.getElementById('ui_icon');
            if(menu){
                menu.style.color = catalog.value.color_title
            }
            const ui_bar = document.getElementById('ui-bar');
            if(ui_bar){
                ui_bar.style.background = catalog.value.color_ui_bars
            }

            if(type != 'in'){
                state.value = 'welcome';
            }
        }, 300);
    }

    const get_products = async () => {
        catalog.value.products = [];
        projectFirestore.collection('Apps').doc('Catalog')
        .collection('Users').doc(projectAuth.currentUser.uid)
        .collection('Products').onSnapshot(snapshot => {
            if(!snapshot.empty){
                snapshot.docChanges().forEach(change => {
                    if(change.type == 'added'){
                        catalog.value.products.push(change.doc.data())
                    }
                })
            }
        })
    }

    const start_catalog = () => {
        if(catalog.value.products && catalog.value.products.length > 0){
            state.value = 'catalog';
            init_catalog_view();
            update_screen('in');
        }else{
            Swal.fire({
                icon: 'info',
                title: 'שים לב',
                text: 'טרם נוספו מוצרים לקטלוג.'
            })
        }
    }

    const drawer = ref(false);
    const current_category = ref();

    const init_catalog_view = () => {
        current_category.value = catalog.value.categories[0]
    }

    const toggle_drawer = () => {
        if(!drawer.value){
            drawer.value = !drawer.value
        }
        const drawer_el = document.getElementById('drawer');
        if(drawer_el){
            if(drawer_el.classList.contains('slide-in-right')){
                drawer_el.classList.replace('slide-in-right', 'slide-out-right');
                return
            }
            if(drawer_el.classList.contains('slide-out-right')){
                drawer_el.classList.replace('slide-out-right', 'slide-in-right');
                return
            }
        }
    }

    return{
        state, catalog, loading, in_construction, start_catalog, current_category,
        drawer, toggle_drawer
    }
}
}
</script>

<style scoped>
.loading{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: whitesmoke;
}
.catalog{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.side-drawer{
    position: absolute;
    width: 40%;
    height: 90%;
    top: 0;
    right: 0;
    background-color: rgb(238, 238, 238);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-top: 5%;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0.5px 0.5px 2px rgba(0, 0, 0, 0.5);
}
.welcome{
    width: 100%;
    text-align: center;
}
.logo-wrapper{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.welcome-logo{
    width: 33%;
    height: auto;
}
.cat-title{
    font-size: 3.5vh;
}
.slogan{
    font-size: 2.5vh;
}
.start-btn{
    margin-top: 20px;
    width: 100%;
    text-align: center;
}
.main-catalog{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-areas: 
    "menu       top-center    logo"
    "path-bar   path-bar  path-bar"
    "category-content    category-content    category-content";
    grid-template-columns: 20% 60% 20%;
    grid-template-rows: 15% 5% 80%;
    padding: 5px;
}
.menu{
    grid-area: menu;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 6vh;
    cursor: pointer;
}
.drawer-header{
    position: relative;
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.close-menu{
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 3vh;
    cursor: pointer;
}
.category-content{
    grid-area: category-content;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.logo{
    grid-area: logo;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.top-center{
    grid-area: top-center;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5px;
}
.img-max{
    max-width: 100%;
    max-height: 100%;
}
.path-bar{
    width: 100%;
    height: 100%;
    grid-area: path-bar;
    display: grid;
    grid-template-areas: "path-home current-category current-sub path-actions";
    grid-template-rows: 100%;
    grid-template-columns: 10% max-content max-content max-content;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0.5px 0.5px 2px rgba(0, 0, 0, 0.5);
}
.path-home{
    width: 100%;
    height: 100%;
    grid-area: path-home;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vh;
    text-align: center;
}
.current-category{
    width: 100%;
    height: 100%;
    grid-area: current-category;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 2vh;
    text-align: center;
}

@media only screen and (max-width: 1004px) {
    .side-drawer{
        width: 85%;
    }
}



.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
.slide-out-right {
	-webkit-animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}

</style>